.operations-box{
	display: flex;
	flex-wrap: wrap;
	hr {
		border-top-color : black;
	}
	a {
		color: black;
		text-decoration: none;
		&:hover,
		&:focus {
		color: $link-hover-color;
		text-decoration: none;
	}
}

.operations-box-child{
	flex-grow: 1;
	flex-basis: 25%;
	background-color: #F1F1F1;
	border: 2px solid black;
	padding: 5px;
	margin: 5px;
	&:hover { box-shadow: 2px 2px 14px 4px #888888; }
	h4 {
		-webkit-text-stroke: 1px black;
		color: $brand-primary;
	  	text-shadow:
		/* Simulated effect for Firefox and Opera
		   and nice enhancement for WebKit */
		-1px -1px 0 #000,
		1px -1px 0 #000,
		-1px  1px 0 #000,
		1px  1px 0 #000;
		}
}

@media screen and (max-width: 480px) {
	.operations-box-child{
		flex-basis: 100%;
	}
}

.Pagination--element{
	margin:auto;
}

#callscorings {
	.overflow {
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.flexcontainer{
		display: flex;
		display: -webkit-flex;
		flex-direction: row;
		align-items: flex-start;
	}
	.staggered-transition {
		transition: all .5s ease;
		overflow: hidden;
		margin: 0;
		height: 20px;
	}
	.staggered-enter, .staggered-leave{
		opacity: 0;
		height: 0;
	}
	.panel{
		height: 19rem;
		&:hover { box-shadow: 1px 1px 7px 2px #888888; }
	}
	.flex-body{
		display:flex;
		height:15.5rem;
	}
	.flex-body-child{
		width:100%;
		height:100%;
		margin:auto;
	}
	.flex-body-child > p {
		width:100%;
		height:33%;
		margin:auto;
	}
	.panel-heading{
		height: 3.5rem;
			h4{
				max-height: 120%;
				height: 120%;
				width: 100%;
				min-width: 100%;
				max-width: 100%;
			}
	}
}
}
