// Flatly 3.3.5
// Bootswatch
// -----------------------------------------------------

@import url("https://fonts.googleapis.com/css?family=Lato:400,700,400italic");

// Navbar =====================================================================

.navbar {
  border-width: 0;

  &-default {

    .badge {
      background-color: #fff;
      color: $navbar-default-bg;
    }
  }

  &-inverse {

    .badge {
      background-color: #fff;
      color: $navbar-inverse-bg;
    }
  }

  &-brand {
    line-height: 1;
  }
}

// Buttons ====================================================================

.btn {
  border-width: 2px;
}

.btn:active {
  @include box-shadow(none);
}

.btn-group.open .dropdown-toggle {
  @include box-shadow(none);
}

// Typography =================================================================

.text-primary,
.text-primary:hover {
  color: $brand-primary;
}

.text-success,
.text-success:hover {
  color: $brand-success;
}

.text-danger,
.text-danger:hover {
  color: $brand-danger;
}

.text-warning,
.text-warning:hover {
  color: $brand-warning;
}

.text-info,
.text-info:hover {
  color: $brand-info;
}

// Tables =====================================================================

table,
.table {

  a:not(.btn) {
    text-decoration: underline;
  }

  .dropdown-menu a {
    text-decoration: none;
  }

  .success,
  .warning,
  .danger,
  .info {
    color: #fff;

    > th > a,
    > td > a,
    > a {
      color: #fff;
    }
  }

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border: none;
  }

  &-bordered > thead > tr > th,
  &-bordered > tbody > tr > th,
  &-bordered > tfoot > tr > th,
  &-bordered > thead > tr > td,
  &-bordered > tbody > tr > td,
  &-bordered > tfoot > tr > td {
    border: 1px solid $table-border-color;
  }
}

// Forms ======================================================================

.form-control,
input, {
  border-width: 2px;
  @include box-shadow(none);

  &:focus {
    @include box-shadow(none);
  }
}

.has-warning {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label,
  .form-control-feedback {
    color: $brand-warning;
  }

  .form-control,
  .form-control:focus {
    border: 2px solid $brand-warning;
  }

  .input-group-addon {
    border-color: $brand-warning;
  }
}

.has-error {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label,
  .form-control-feedback {
    color: $brand-danger;
  }

  .form-control,
  .form-control:focus {
    border: 2px solid $brand-danger;
  }

  .input-group-addon {
    border-color: $brand-danger;
  }
}

.has-success {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  &.radio label,
  &.checkbox label,
  &.radio-inline label,
  &.checkbox-inline label,
  .form-control-feedback {
    color: $brand-success;
  }

  .form-control,
  .form-control:focus {
    border: 2px solid $brand-success;
  }

  .input-group-addon {
    border-color: $brand-success;
  }
}

// Navs =======================================================================

.nav {
  .open > a,
  .open > a:hover,
  .open > a:focus {
    border-color: transparent;
  }
}

.pager {
  a,
  a:hover {
    color: #fff;
  }

  .disabled {
    &>a,
    &>a:hover,
    &>a:focus,
    &>span {
      background-color: $pagination-disabled-bg;
    }
  }
}

// Indicators =================================================================

.close {
  color: #fff;
  text-decoration: none;
  opacity: 0.4;

  &:hover,
  &:focus {
    color: #fff;
    opacity: 1;
  }
}

.alert {

  .alert-link {
    color: #fff;
    text-decoration: underline;
  }
}

// Progress bars ==============================================================

.progress {
  height: 10px;
  @include box-shadow(none);
  .progress-bar {
    font-size: 10px;
    line-height: 10px;
  }
}

// Containers =================================================================

.well {
  @include box-shadow(none);
}

a.list-group-item {

  &.active,
  &.active:hover,
  &.active:focus {
    border-color: $list-group-border;
  }

  &-success {
    &.active {
      background-color: $state-success-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($state-success-bg, 5%);
    }
  }

  &-warning {
    &.active {
      background-color: $state-warning-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($state-warning-bg, 5%);
    }
  }

  &-danger {
    &.active {
      background-color: $state-danger-bg;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($state-danger-bg, 5%);
    }
  }
}

.panel {
  &-default {
    .close {
      color: $text-color;
    }
  }
}

.modal {
  .close {
    color: $text-color;
  }
}

.popover {
  color: $text-color;
}
