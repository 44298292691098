$alexandra: #AA061D;
$alex-darker: #61000D;
$alex-dark: #890013;
$alex-light: #D2142F;
$alex-lighter: #E14459;

$shadow: 0px 1px 7px 4px $alex-darker;

[v-cloak] {
  display: none;
}

@mixin operation-App-active-link
{
	background-color: $alex-dark !important; text-decoration: none;
}
@mixin operation-App-content
{
	flex-basis: 100%;
	margin-top: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-wrap: wrap;
}
@mixin large-and-flexible {
	flex-basis: 100%;
	display: flex;
}

@mixin round-and-white {
	background-color:white;
	margin: 10px;
	border-radius: 5px;
}
@mixin flex-and-wrap{
	display: flex;
	flex-wrap: wrap;
}
@mixin vuestrapCleanModal{
	.modal-content {
		border-radius: 5px;
		span {
			color: $alex-darker;
			font-size: 1.3em;
		}
		.modal-footer {
			button {
				border-radius: 10px;
				span {
					&:hover {
						transform: scale(1.1, 1.1);
					}
					font-size: 1em;
				}
			}
		}
		.modal-body {
			h1 {
				color: $alexandra;
			}
		}
	}
	.Add-box {
		@include flex-and-wrap;
		justify-content: space-around;
		align-content: center;
		text-align: center;
		.separator {
			margin-top: 10px;
			margin-bottom: 10px;
			flex-basis: 100%;
			border-radius: 10px;
			border: 3px solid $alex-dark;
		}
		.input-group-addon {
			background-color: $alexandra;
		}
		.Add-box-child-large {
			margin-top: 5px;
			margin-bottom: 5px;
			flex-basis: 100%;
			@extend .Add-box;
		}
		#bottom-basket {
			span {
				color: green;
				&:hover {
					color: black;
					transform: scale(0.8, 0.8);
				}
			}
		}
		.Add-box-child {
			margin-right: 5px;
			margin-left: 5px;
			flex-grow: 1;
			@include flex-and-wrap;
			&:nth-child(2n) {
				span {
					color: $alexandra;
				}
			}
			span {
				&:hover {
					transform: scale(1.1, 1.1);
					cursor: pointer;
					color: green;
				}
				flex-grow: 1;
				margin: auto;
			}
		}
	}
}
