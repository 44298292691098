@import "conf/variables";
@import "confbs/bootstrap";
@import "confbw/bootswatch";
@import "confbw/variables";

.navbar {
    &.navbar-inverse, &.navbar-collapse, &.navbar-inverse, &.navbar-form {
        border-bottom: 3px solid $navbar-inverse-border !important;

        & a.navbar-brand {
            background: $navbar-inverse-border;
            color: white;
        }
    }
}
.navbar-nav {
    > .open > a {
        &,
        &:hover,
        &:focus {
             background-color: $brand-primary;
        }
    }
}

//Collapse navbar earlier
@media (max-width: 1040px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-collapse.collapse.in {
        display: block!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
}

.row.compare {
    margin-top: 10px;
    padding: 5px;
    background: lighten($gray-lighter, 3%);
}

.cohdabutton {
    $color: #fff;
    $background: #585858;
    $border: rgba(0, 0, 0, 0);

    @include button-variant($color, $background, $border);

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        background-color: darken($background, 20%);
        border-color: darken($border, 20%);
    }
}

.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
    z-index: 2;
    box-sizing: border-box;
    border: 1px solid rgb(255, 240, 218);

    /* box-sizing: border-box; */
    outline-style: inset;
    outline-width: 1px;
    outline-color: rgb(0, 0, 0);
}

//Glyphicon Size
.gi-08x {
    font-size: .8em;
}
.gi-1x {
    font-size: 1.3em;
}

.gi-2x {
    font-size: 2em;
}

.gi-3x {
    font-size: 3em;
}

.gi-4x {
    font-size: 4em;
}

.gi-5x {
    font-size: 5em;
}

//Btn-group border
.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
}

.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
    float: none;
    display: table-cell;
    width: 1%;
    border-width: 1px;
    border-color: white;
}

//Custom glyphicon
.custom-glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

//Main layout fixes

body {
    padding-top: 60px;

    /* Margin bottom by footer height */
    margin-bottom: 60px;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;

    /* Set the fixed height of the footer here */
    height: 60px;
    background-color: slategray;
}

.container .text-muted {
    margin: 20px 0;
}

nav + .jumbotron {
    background-color : $gray-base;
    text-align: center;
    // margin-top: 60px;
        .progress {
        background: rgba(245, 245, 245, 0);
        border-radius: 0;
        height: 20px;
    }

    .progress-bar-custom {
        background: rgba(133, 4, 4, 1);
        background: -webkit-linear-gradient(top, rgba(163, 29, 38, 0.01) 0%, rgba(133, 4, 4, 1) 100%);
        background: linear-gradient(to bottom, rgba(163, 29, 38, 0.01) 0%, rgba(133, 4, 4, 1) 100%);
    }

    .progress-striped .progress-bar-custom {
        background-color: rgba(133, 4, 4, 1);
        background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(64, 0, 0, 0.22), color-stop(0.25, transparent) color-stop(0.5, transparent), color-stop(0.5, rgba(64, 0, 0, 0.22)), color-stop(0.75, rgba(64, 0, 0, 0.22)), color-stop(0.75, transparent), to(transparent)));
        background-image: -webkit-linear-gradient(45deg, rgba(64, 0, 0, 0.22) 25%, transparent 25%, transparent 50%, rgba(64, 0, 0, 0.22) 50%, rgba(64, 0, 0, 0.22) 75%, transparent 75%, transparent);
        background-image: linear-gradient(45deg, rgba(64, 0, 0, 0.22) 25%, transparent 25%, transparent 50%, rgba(64, 0, 0, 0.22) 50%, rgba(64, 0, 0, 0.22) 75%, transparent 75%, transparent);
        background-size: 13px 13px;
    }

    .progress-bar:hover{
        border: 2px solid floralwhite;
    }
    h1, h2, h3, i {
        color : $body-bg;
    }
    i:hover{
       color : $body-bg;
    }
    h4, h5, cite i {
        color : $brand-tertiary;
    }
}
@import "app/callscoringList";
@import "app/operations";
@import "app/timesheets";
