// $alexandra: #A6C843;
// $alex-darker: #89AC24;
// $alex-dark: #68880C;
// $alex-light: #C9E86E;
// $alex-lighter: #DFF69D;
// $alexandra: #A2032A;
// $alex-darker: #5B0017;
// $alex-dark: #830021;
// $alex-light: #C6113E;
// $alex-lighter: #D83D63;
// $alexandra: #AA061D;
// $alex-darker: #61000D;
// $alex-dark: #890013;
// $alex-light: #D2142F;
// $alex-lighter: #E14459;
@import "operationsVariables";
#vue-operation {
  @include flex-and-wrap;
  margin-top: 0;
  padding-top: 0;
  background-color: $alexandra;
  padding: 20px;
  align-items: strech;

  //Span
  //_____________________________________
  span {
    color: $alexandra;
    &:hover {
      color: $alex-lighter;
      transform: scale(1.5,1.5);
    }
  }

  //Hx
  //_____________________________________
  h1 {
    text-align: center;
    color: $body-bg;
    padding: 0;
    margin: 0 20px 20px;
    flex-basis: 100%;
  }
  h2 {
    color: $alex-lighter;
  }

  //Nav
  //_____________________________________
  nav {
    flex-basis: 100%;
    justify-content: space-around;
    @include flex-and-wrap;
    .fake-button {
      flex-basis: 20%;
      text-align: center;
      padding: 10px;
      color: $alex-lighter;
      background-color: $alexandra;
      border-radius: 10px;
      &:focus {
        @include operation-App-active-link;
      }
      &:hover {
        @include operation-App-active-link;
      }
    }
    @media screen and (max-width: 480px) {
      .fake-button {
        flex-basis: 50%;
        font-size: 1.5em;
      }
    }
    .v-link-active {
      @include operation-App-active-link;
    }
    a {
      margin: auto;
      color: $alex-lighter;
      background-color: $alexandra;
      &::before {
        content: '#';
      }
      font-size: 2em;
    }
  }

  //Forms
  //_____________________________________
  .form-add-mother {
    display: flex;
    flex-basis: 100%;
    margin-top: 10px;
    justify-content: space-between;
    .form-add-child {
      flex-basis: 30%;
      flex-grow: 1;
      margin: 5px;
    }
  }
  .form-aside-mother {
    @include flex-and-wrap;
    .form-aside-child {
      flex-grow: 1;
      flex-basis: 100%;
      margin-bottom: 10px;
    }
    .input-group-addon-round {
      color: $alexandra;
    }
  }
  .form-large-button {
    display: flex;
    button {
      border-radius: 10px;
    }
  }
  .huge-button {
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    margin: 10px;
    button {
      font-size: 1.5em;
      border-radius: 10px;
      flex-basis: 100%;
      flex-grow: 1;
      height: 75px;
    }
  }

  //Utility
  //_______________________________
  .grow {
    flex-grow: 1;
  }

  //Aside
  //_______________________________
  .aside-header {
    background-color: $alexandra;
    h4 {
      color: $alex-lighter;
    }
  }

  //Alert
  //_______________________________
  .alert {
    z-index: 2000;
    top: 100px;
    border-radius: 10px;
    h1 {
      font-size: 1.5em;
      margin: 0;
      padding: 0;
      color: green;
    }
    p {
      &::before {
        content: '> ';
      }
      font-weight: bold;
    }
  }
  .alert-danger {
    h1 {
      color: $alexandra;
    }
  }

  //Inputs
  //_______________________________
  .input-field {
    background-color: white;
    flex-basis: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    &:focus {
      border-color: $alex-lighter;
    }
  }
  .input-group-addon-round {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
  }

  // DetailsContent
  //_________________________________________
  .details-content {
    @include operation-App-content;
    justify-content: space-around;
    align-content: space-around;
  }

  // TeamContent
  //_________________________________________
  .team-content {
    @include operation-App-content;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-end;
    @include vuestrapCleanModal;
  }

  // DailyContent
  //_________________________________________
  .daily-content {
    @extend .team-content;
    h5 {
      color: $alex-lighter;
    }
  }

  //Hours
  .hours-content {
    @include operation-App-content;
    justify-content: space-around;
    align-content: space-around;
    .modal-content {
      border-radius: 5px;
      span {
        color: $alex-darker;
        font-size: 1.3em;
        &:hover {
          transform: none !important;
        }
      }
      .modal-footer {
        button {
          border-radius: 10px;
          span {
            font-size: 1em;
          }
        }
      }
      .modal-body {
        padding-bottom: 0px;
        h1 {
          color: $alexandra;
        }
      }
      .Add-box {
        @include flex-and-wrap;
        justify-content: space-around;
        align-content: center;
        text-align: center;
        .separator {
          margin-top: 10px;
          margin-bottom: 10px;
          flex-basis: 100%;
          border-radius: 10px;
          border: 2px solid $alex-dark;
        }
        .input-group-addon {
          background-color: $alexandra;
        }
        .Add-box-child-nav{
          margin-top: 10px;
          flex-basis:100%;
          display:flex;
          .nav-button{
            flex-grow: 1;
            font-size: 1.5em;
            border-radius: 10px;
            margin-right:20px;
            margin-left:20px;
            color: $alex-lighter;
            background-color: $alex-dark;
            &:hover{
              transform: scale(1.1,1.1);
              box-shadow:inset 0px 0px 0px 3px $alex-darker;
              cursor: pointer;
            }
          }
          .focused{
            transform: scale(1.1,1.1);
            box-shadow:inset 0px 0px 0px 3px $alex-darker;
            cursor: pointer;
          }
        }
        .Add-box-child-large {
          margin-top: 5px;
          margin-bottom: 5px;
          flex-basis: 100%;
          @include flex-and-wrap;
          justify-content: space-around;
          align-content: center;
          text-align: center;
          .fix-height{
            padding-top: 5px;
          }
          .Add-box-child-medium{
            padding: 10px;
            flex-basis: 50%;
          }
        }
        #bottom-basket {
          span {
            color: green;
          }
        }
      }
    }
  }

  Calendar
  #calendar{
    .fc-title{
      margin-top:5px;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
      &:active{
        text-decoration: none;
      }
    }
    .fc-event{
      background-color: $alex-dark;
      border-color: $alex-darker;
    }
    span{
      color: #7F3F3F;
    }
    .fc-prev-button, .fc-next-button{
      span{
        color: $alex-light;
      }
    }
    a{
      text-decoration: none;
    }
  }
  //End
}
