@import "operationsVariables";
@mixin stylish{
	background-color: white;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
}
.Timesheet-header{

	// The Right Operation box
	.Operation-layout{
		display: flex;
		flex-wrap: wrap;
		flex-basis: 100%;
		flex-grow: 1;
		text-align: center;
		margin-left: 10px;
		padding: 5px;
		max-height: 275px;
		overflow: auto;
		@include stylish;
		// The Operations
		.Operation-box{
			// flex-direction: column;
			text-align: center;
			flex-basis: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			align-content: flex-start;
			// align-self: stretch;
			//Each Operation
			.Operation-child{
				flex-basis: 25%;
				flex-grow: 1;
				box-shadow: 2px 2px 2px 1px $alex-lighter;
				margin: 1px;
				color: white;
				background-color : $alexandra;
				font-size: 1.1em;
				font-variant: small-caps;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow:hidden;
				&:hover{
					cursor: pointer;
					background-color : $alex-light;
				}
			}
		}
		// The Add Operation layout
		.Operation-add{
			margin-top: 10px;
			align-self: flex-end;
			flex-grow: 1;
			flex-basis: 100%;
			display:flex;
			//The button itself
			.fake-button{
				margin: auto;
				border: 2px solid lightgreen;
				color: white;
				background-color: green;
				border-radius: 5px;
				padding: 3px 10px 3px 10px;
				&:hover{
					cursor: pointer;
					transform: scale(1.1,1.1);
				}
			}
		}
	}
}
.Agent-Routing{
	flex-basis: 100%;
	padding: 10px;
	display:flex;
	flex-wrap: wrap;
	margin-top : 10px;
  	// font-size: 1.1em;
	font-variant: small-caps;
	@include stylish;
	.agent-button{
		text-align : center;
		color: white;
		flex-grow: 1;
		flex-basis: 17%;
		box-shadow: 2px 2px 2px 1px $alex-lighter;
		border-radius: 10px;
		margin: 5px;
		color: white;
		background-color : $alexandra;
		&:hover{
			cursor: pointer;
			background-color : $alex-light;
		}
		// margin: auto;
	}
}
@mixin Timesheet-layout
{
	display: flex;
	flex-wrap: wrap;
	flex-basis: 100%;
	text-align: center;
	margin-left: 5px;
	margin-right: 5px;
	padding: 5px;
	@include stylish;
}
.Timesheet-body{
	margin-top : 10px;
	display:flex;
	flex-wrap: wrap;
	.Inputs-layout{
		.remove-button{
			margin: 10px 0px 0px 5px;
			&:hover{
				color: red;
				cursor: pointer;
			}
		}
		@include Timesheet-layout;
		.fake-table{
			flex-basis: 100%;
			.titles{
				display:flex;
				.title{
					font-size: 1.5em;
					margin: auto;
					span{
						color: $alexandra;
						&:hover{
							cursor:pointer;
						}
					}
				}
			}
			.body{
				.buttons{
					margin-bottom: 10px;
					display: flex;
					flex-wrap: wrap;
					.fake-button{
						flex-basis:51%;
						margin: auto;
						border: 2px solid lightgreen;
						color: white;
						background-color: green;
						border-radius: 5px;
						padding: 3px 10px 3px 10px;
						&:hover{
							cursor: pointer;
							transform: scale(1,1.1);
							box-shadow: inset 0px 0px 0px 2px white;
							}
						}
				}
				.input-row{
					flex-grow: 1;
					flex-basis: 100%;
					display: flex;
					margin-top: 5px;
				}
				.separator{
					margin: 20px 0px 20px 0px;
					border-radius: 5px;
					border-width: 2px 0px 2px 0px;
					border-color: #F1F1F1;
					border-style: solid;
				}
			}
		}
	}
	.HourDisplay-layout{
		display:flex;
		h2{
			text-align: left;
			flex-basis:100%;
			margin-left: 20px;
		}
		h4{
			text-align: left;
			flex-basis:100%;
			margin: 0px 0px 10px 20px;
		}
		h5{
			text-align: left;
			flex-basis:100%;
			margin: 0px 0px 10px 20px;
		}
		margin-top: 10px;
		margin-bottom: 20px;
		@include Timesheet-layout;
		.fake-table{
			flex-basis: 100%;
			.titles{
				display: flex;
				.title{
					font-size: 1.5em;
					flex-basis: 15%;
					flex-grow : 1;
					border-radius: 5px;
					margin: 2px;
				}
			}
			.body{
				text-align: center;
				display:flex;
				flex-basis: 100%;
				flex-wrap:wrap;
				.row-elements{
					flex-basis: 100%;
					display:flex;
					.element{
						flex-basis: 15%;
						flex-grow : 1;
						background-color: #F1F1F1;
						border-radius: 10px;
						color: $alexandra;
						font-size: 1.2em;
						margin: 5px;
						padding: 10px;
						overflow : hidden;
						text-overflow: ellipsis;
						display: flex;
						flex-wrap: wrap;
						p{
							overflow : hidden;
							text-overflow: ellipsis;
							margin: auto;
						}
						.comment{
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow:hidden;
							max-width: 160px;
						}
						&:hover{
							border: 2px solid $alex-lighter;
						}
					}
				}
			}
		}
	}
}
.vue-timesheets{
	//The calendar
	.calendar{
		flex-basis: 25%;
		flex-grow: 1;
		@include stylish;
		padding: 10px;
		.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover{
			background-color: $alexandra;
		}
		.bootstrap-datetimepicker-widget table td.today:before{
			border-bottom-color : $alexandra;
		}
		.picker-switch{
			text-transform: capitalize;
		}
	}
	.suchcalendar{
			background-color:white;
			padding: 10px;
			.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover{
				background-color: $alexandra;
			}
			.bootstrap-datetimepicker-widget table td.today:before{
				border-bottom-color : $alexandra;
			}
			.picker-switch{
				text-transform: capitalize;
			}
	}
  //Inputs
  //_______________________________
  .input-field {
    background-color: white;
    flex-basis: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    &:focus {
      border-color: $alex-lighter;
    }
  }
  .input-group-addon-round {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
  }
 //Forms
  //_____________________________________
  .form-add-mother {
    display: flex;
    flex-basis: 100%;
    margin-top: 10px;
    justify-content: space-between;
    .form-add-child {
      flex-basis: 30%;
      flex-grow: 1;
      margin: 5px;
    }
  }
  .form-aside-mother {
    @include flex-and-wrap;
    .form-aside-child {
      flex-grow: 1;
      flex-basis: 100%;
      margin-bottom: 10px;
    }
    .input-group-addon-round {
      color: $alexandra;
    }
  }
  .form-large-button {
    display: flex;
    button {
      border-radius: 10px;
    }
  }
  .huge-button {
    flex-basis: 100%;
    flex-grow: 1;
    display: flex;
    margin: 10px;
    button {
      font-size: 1.5em;
      border-radius: 10px;
      flex-basis: 100%;
      flex-grow: 1;
      height: 75px;
    }
  }

  //Utility
  //_______________________________
  .grow {
    flex-grow: 1;
  }

  //Aside
  //_______________________________
  .aside-header {
    background-color: $alexandra !important;
    h4 {
      color: $alex-lighter;
    }
  }

	@include vuestrapCleanModal;
	.legende{
		display: flex;
		flex-wrap : wrap;
		h5, h6{
			flex-grow: 1;
			flex-basis: 25%;
		}
	}

}
